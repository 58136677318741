import Layout from "@/screens/Layout";
import dynamic from "next/dynamic";
import { Mukta } from "next/font/google";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect, useState } from "react";

import "@/styles/globals.scss";
import "../component/constituency/index.css";
import "../component/ExitPollRegionalV2/index.css";
import { ampGlobalStyles } from "@/component/Amp/styles";
import useIsMobile from "@/hooks/useIsMobile";
import sendGAEvent from "@/utils/gaEvent";
import { MasterConfigContext } from "@/context/MasterConfigContext";
import ComscoreScript from "@/component/Ads/ComscoreScript";
import ChartbeatScript from "@/component/Ads/ChartbeatScript";
const OpenWrapScript = dynamic(() => import("@/component/Ads/OpenWrapScript"));
const AmazonAds = dynamic(() => import("@/component/Ads/AmazonAds"));

const VDOAdsScript = dynamic(() => import("@/component/Ads/VDOAdsScript"));
const AmpLayout = dynamic(() => import("@/screens/Layout/AmpLayout"));
const SiteNavigationSchema = dynamic(() =>
  import("@/component/SeoTags/SiteNavigationSchema")
);
const OrganisationSchema = dynamic(() =>
  import("@/component/SeoTags/OrganisationSchema")
);
// const WebpageSchema = dynamic(() =>
//   import("@/component/SeoTags/WebpageSchema")
// );
const WebsiteSchema = dynamic(() =>
  import("@/component/SeoTags/WebsiteSchema")
);
const MagniteAnalytics = dynamic(()=> import("@/component/Ads/MagniteScript"));

// const AdsScript = dynamic(() => import("@/component/Ads/AdsScript"));

const muktaFont = Mukta({
  subsets: ["latin"],
  weight: "400",
  display: "swap",
  variable: "--font-mukta",
});

function App({ Component, masterConfigData, ...props }) {
  const router = useRouter();
  const isAmp = router?.asPath?.startsWith("/amp");
  const isShareRoute = router?.asPath?.startsWith("/share/");
  const isPhotoGallery = router?.asPath?.includes("/photo/");
  const isMobileDevice = useIsMobile();
 
  const isElectionRoute =
    router?.asPath === "/elections/lok-sabha-elections-2024/results" ||
    router?.asPath.includes("/haryana-nikay-chunav") ||
    router?.asPath.includes("/board-results") ||
    router?.asPath === "/elections/lok-sabha-elections-2024/schedule" ||
    router?.asPath === "/elections/lok-sabha-elections-2024" ||
    router?.asPath?.includes("/election/haryana-assembly") ||
    router?.asPath?.includes("/election/maharashtra-assembly") ||
    router?.asPath?.includes("/election/jharkhand-assembly") ||
    router?.asPath?.includes("/election/delhi-assembly") ||
    router?.asPath ===
      "/elections/lok-sabha-elections-2024/constituency-list" ||
    router?.asPath === "/elections/faqs" ||
    (router?.asPath?.includes("/elections/lok-sabha-elections-2024") &&
      router?.asPath?.includes("-constituency-result-"));
  // const [loadScript, setLoadScript] = useState(false);

  // useEffect(() => {
  //   console.log("useEffect loaded");
  //   window.onload = function () {
  //     console.log("window loaded");
  //     setLoadScript(true);
  //   };
  // }, []);
  const isCall = router?.asPath?.includes("/delhi-assembly")
  const [hasScrolled, setHasScrolled] = useState(false);
  useEffect(() => {
    // if(isCall){
    const handleScroll = () => {
      if (!hasScrolled) {
        setHasScrolled(true);
      } 
    // };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
   }
  }, []);
  useEffect(() => {
    const updateFavicon = () => {
      let faviconUrl = "/favicon.ico"; // Default favicon
      if (router.asPath.includes("/rajasthan" || "/amp/rajasthan")) {
        faviconUrl = "/favicon_rj.ico";
      } else if (
        router.asPath.includes("/madhya-pradesh" || "/amp/madhya-pradesh")
      ) {
        faviconUrl = "/favicon_mp.ico";
      } else if (router.asPath.includes("/bihar" || "/amp/bihar")) {
        faviconUrl = "/favicon_bihar.ico";
      } else if (router.asPath.includes("/haryana" || "/amp/haryana")) {
        faviconUrl = "/favicon_haryana.ico";
      }
      // Dynamically update favicon
      const link = document.querySelector("link[rel='icon']");
      if (link) {
        link.href = faviconUrl;
      }
    };

    updateFavicon();
    async function onFirstScroll() {
      window._izq = window._izq || [];
      window._izq.push(["init"]);

      const izooto = document.createElement("script");
      izooto.setAttribute(
        "src",
        "https://cdn.izooto.com/scripts/c4b2e3a7dec48a6951b66a68aa8641118c2e6502.js"
      );
      izooto.setAttribute("async", "true");
      document.head.appendChild(izooto);
    }

    function handleScroll() {
      onFirstScroll();

      window.removeEventListener("scroll", handleScroll);
    }

    window.addEventListener("scroll", handleScroll);

    //* send ga event for first time user visit
    let isFirstTimeVisit = localStorage?.getItem("first_visit");
    // console.log("isFirstTimeVisit", isFirstTimeVisit);
    if (!isFirstTimeVisit) {
      sendGAEvent({
        eventName: "first_visit",
        pageLocation: window?.location?.href,
        pageTitle: document?.title,
      });
      localStorage.setItem("first_visit", true);
    }
  }, []);

  const GACode = process.env.NEXT_PUBLIC_GACODE ?? "";
  useEffect(() => {
    const loadGAScript = () => {
      // Dynamically create and load the GA script
      const script = document.createElement("script");
      script.id = "gaScript";
      script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GACODE}`;
      script.async = true;
      script.onload = () => {
        // Once the script is loaded, create the GA configuration script
        const gaCodeScript = document.createElement("script");
        gaCodeScript.id = "gaCode";
        gaCodeScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GACODE}');
        `;
        document.head.appendChild(gaCodeScript);
      };
      document.head.appendChild(script);
    };
    loadGAScript();
    
}, []);
  
  return isAmp ? (
    <>
      <style jsx global>
        {ampGlobalStyles}
      </style>

      <Script
        async
        id="mgid-amp-ad"
        custom-element="amp-ad"
        src="https://cdn.ampproject.org/v0/amp-ad-0.1.js"
      ></Script>
      <Script
        async
        custom-element="amp-analytics"
        src="https://cdn.ampproject.org/v0/amp-analytics-0.1.js"
      ></Script>
      <Script
        async
        custom-element="amp-sidebar"
        src="https://cdn.ampproject.org/v0/amp-sidebar-0.1.js"
      ></Script>
      <Script
        async
        custom-element="amp-sticky-ad"
        src="https://cdn.ampproject.org/v0/amp-sticky-ad-1.0.js"
      ></Script>
      <Script
        async
        custom-element="amp-accordion"
        src="https://cdn.ampproject.org/v0/amp-accordion-0.1.js"
      ></Script>

      <SiteNavigationSchema data={props?.menu} />
      <OrganisationSchema />
      <MasterConfigContext.Provider value={masterConfigData}>
        <AmpLayout menuData={props?.menu} isPhotoGallery={isPhotoGallery}>
          <amp-analytics
            config="https://www.googletagmanager.com/amp.json?id=GTM-T62NS66&gtm.url=SOURCE_URL"
            data-credentials="include"
          ></amp-analytics>
          <Component {...props.pageProps} />
        </AmpLayout>
      </MasterConfigContext.Provider>
    </>
  ) : isShareRoute ? (
    <MasterConfigContext.Provider value={masterConfigData}>
      <Component {...props.pageProps} />
    </MasterConfigContext.Provider>
  ) : (
    <>
      <style jsx global>{`
        html {
          font-family: ${muktaFont.style.fontFamily};
        }
      `}</style>

      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5"
        />
        <link rel="preconnect" href="https://platform.twitter.com" />
        <link rel="dns-prefetch" href="https://platform.twitter.com" />
      </Head>
      <SiteNavigationSchema data={props?.menu} />
      <OrganisationSchema />
      {/* <WebpageSchema /> */}
      <WebsiteSchema />
    {   <OpenWrapScript/>}
      <MagniteAnalytics />
      <AmazonAds />
      <ComscoreScript/>
      <ChartbeatScript/>
      {/* <AdsScript /> */}
      {/* <VDOAdsScript /> */}
      {!isElectionRoute  && <VDOAdsScript />}
      <MasterConfigContext.Provider value={masterConfigData}>
        <Layout menuData={props?.menu} isMobileDevice={isMobileDevice}>
          <Component {...props.pageProps} />
        </Layout>
      </MasterConfigContext.Provider>
      {/* </Provider> */}
      {/** third party scripts */}
      {/* {loadScript && (
        <script
          strategy="lazyOnload"
          id="newrelic"
          src={`${process.env.NEXT_PUBLIC_NEWRELIC_JS}`}
        ></script>
      )} */}
      {/* <script
        id="gtmScript"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-KB6DXDKD');
                `,
        }}
      /> */}

      {/* <script
        async
        id="gaScript"
        // strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${GACode}`}
      />

      <script
        async
        id="gaCode"
        // strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${GACode}');`,
        }}
      /> */}
    </>
  );
}

App.getInitialProps = async (appCtx) => {
  const { logger } = await import("@/utils/logging/log-util");

  try {
    // Check if running on server
    let isServer = !!appCtx.ctx.req;

    let menu = null;
    let masterConfigData = null;
    if (isServer) {
      console.log("App.js running on the server");
      // const { getMenu } = await import("@/handlers/menu");
      // menu = await getMenu();
      try {
        const PORT = process.env.PORT;
        const response = await fetch(`http://localhost:${PORT}/api/menu`);
        if (!response.ok) throw new Error("Network response was not ok");
        menu = await response.json();
      } catch (error) {
        console.error("Menu Fetch error", error);
        // Handle client-side fetch error
      }
      const masterConfig = await fetch(
        `${
          process.env.BASE_PATH + process.env.API_PREFIX
        }/staticconfig?id=masterconfig`
      ).then(async (res) => {
        return await res.json();
      });
      masterConfigData = masterConfig?.data;
    } else {
      console.log("Running on the client");
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_WEBSITE_URL}/api/menu`
        );
        if (!response.ok) throw new Error("Network response was not ok");
        menu = await response.json();
      } catch (error) {
        console.error("Menu Fetch error", error);
        // Handle client-side fetch error
      }
    }

    return {
      menu,
      masterConfigData,
    };
  } catch (error) {
    logger.error(error);
    return {
      menu: [],
    };
  }
};

export default App;
